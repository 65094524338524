import axios from 'axios';

declare const window: any;

const axiosInstance = axios.create({
	baseURL: window.API_URL,
});
// 'https://api.new-dashboard.dev.deepchain.bio'

export const rawGet = <T>(url: string, config: Record<string, string> = {}) => {
	return axiosInstance.get<T>(url, config);
};

export const get = url => {
	return axiosInstance.get(url).then(
		response => {
			return response.data;
		},
		error => {
			return Promise.reject(error);
			//todo:redirect to oops page
		}
	);
};

export const post = (url, data?) => {
	return axiosInstance.post(url, data).then(
		response => {
			return response.data;
		},
		error => {
			return Promise.reject(error);
			//todo:redirect to oops page
		}
	);
};

class AuthInterceptor {
	token: any;

	constructor() {
		this.token = '';
		this.intercept = this.intercept.bind(this); // Bind this to the intercept method
	}

	setAuthGetter(token) {
		this.token = token;
	}

	async intercept(config) {
		if (!this.token) {
			return config;
		}
		try {
			const token = this.token;
			config.headers['Authorization'] = `Bearer ${token}`;
		} catch (e) {
			console.log(e);
		}
		return config;
	}
}

export const authInterceptor = new AuthInterceptor();
axiosInstance.interceptors.request.use(authInterceptor.intercept);

export { axiosInstance as Api };
