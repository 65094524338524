import React from 'react';
import NavSideBar from './sidebar';
import { Layout } from 'antd';
import * as Styled from './styled';

const { Content, Sider } = Layout;

export default function LayoutA({ children }) {
  const [collapsed, setCollapsed] = React.useState(false);
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
        <NavSideBar collapsed={collapsed} />
      </Sider>
      <Layout>
        <Content>
          <Styled.Content>
            {children}
          </Styled.Content>
        </Content>
      </Layout>
    </Layout>
  );
}
