import styled from 'styled-components';


export const LogoContainer = styled.div`
	display: flex;
    height: 41px;
	margin: 1rem;
	color: #fff;
	transition: none;
`;


export const MenuContainer = styled.div`
	margin-top: 1rem;
	display : flex;
	flex-direction : column;
	justify-content: space-between;
	height : calc(100% - 80px);
`;
export const UserDetailsContainer = styled.div`
	padding:.5rem;
	display : flex;
	flex-direction : row;
	justify-content: space-between;
	align-items:center;
`;

export const UserDetails = styled.div`
	display : flex;
	flex-direction : row;
	justify-content: space-between;
	align-items:center;
	gap:.5rem;
	
	.ant-typography{
		color :#fff
	}
`;


