import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';

interface Auth0ProviderWithConfigProps {
	children: React.ReactNode;
}

const domain =
	process.env.REACT_APP_AUTH0_DOMAIN ||
	(window as any).REACT_APP_AUTH0_DOMAIN;
const clientId =
	process.env.REACT_APP_AUTH0_CLIENT_ID ||
	(window as any).REACT_APP_AUTH0_CLIENT_ID;
const audience =
	process.env.REACT_APP_AUTH0_AUDIENCE ||
	(window as any).REACT_APP_AUTH0_AUDIENCE;
const redirectUri = window.location.origin;

export const Auth0ProviderWithHistory = ({
	children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>) => {
	const history = useHistory();

	const onRedirectCallback = (appState?: AppState) => {
		history.push(appState?.returnTo || window.location.pathname);
	};

	return (
		<Auth0Provider
			domain={domain!}
			clientId={clientId!}
			onRedirectCallback={onRedirectCallback}
			authorizationParams={{
				audience,
				redirect_uri: redirectUri,
			}}>
			{children}
		</Auth0Provider>
	);
};
