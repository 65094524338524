import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
	color: ${p => p.theme.textSecondary};
	font-size: 0.85em;
	text-decoration: none;
	margin-left: 10px;
	&:hover {
		color: ${p => p.theme.primary};
	}
`;
