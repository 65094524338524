import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import configureStore from 'core/store';
import AsyncIntlProvider from 'core/i18n/AsyncIntlProvider';
import { themes } from 'core/styles/themes';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

const { store, persistor } = configureStore();

const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOM.render(
	<Provider store={store}>
		<PersistGate persistor={persistor}>
			<React.Suspense fallback={null}>
				<AsyncIntlProvider>
					<BrowserRouter>
						<ThemeProvider theme={themes.light}>
							<App />
						</ThemeProvider>
					</BrowserRouter>
				</AsyncIntlProvider>
			</React.Suspense>
		</PersistGate>
	</Provider>,
	MOUNT_NODE
);
