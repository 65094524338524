import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import { Avatar, Button, Menu, Typography } from 'antd';

import { navItems } from 'core/navItems';

import fullLogo from 'assets/logos/deepchain-logo.svg';
import miniLogo from 'assets/logos/deepchain-logo-mini.svg';
import * as Styled from './styled';
import { useAuth0 } from '@auth0/auth0-react';

const { Text } = Typography;

export default function Sidebar({ collapsed }) {
	const { user, logout } = useAuth0();

	const location = useLocation();
	const dispatch = useDispatch();

	const Logout = (e: any) => logout();

	return (
		<>
			<Styled.LogoContainer>
				<SVG src={collapsed ? miniLogo : fullLogo} />
			</Styled.LogoContainer>
			<Styled.MenuContainer>
				<Menu
					theme='dark'
					selectedKeys={[location.pathname]}
					mode='inline'>
					{navItems.map(item => (
						<Menu.Item key={item.link} icon={item.icon}>
							{item.label}
							<Link to={item.link}></Link>
						</Menu.Item>
					))}
				</Menu>

				<Styled.UserDetailsContainer>
					<div>
						{user ? (
							<Styled.UserDetails>
								<Avatar size={35} src={user?.picture} />
								<Text strong>
									{user?.user_metadata?.given_name}{' '}
									{user?.user_metadata?.family_name}
								</Text>
							</Styled.UserDetails>
						) : (
							<Avatar>U</Avatar>
						)}
					</div>
					<Button onClick={Logout} danger type='primary' size='small'>
						Logout
					</Button>
				</Styled.UserDetailsContainer>
			</Styled.MenuContainer>
		</>
	);
}
