const lightTheme = {
	primary: 'rgba(105, 147, 255, 1)',
	text: 'rgba(58,52,51,1)',
	textSecondary: 'rgba(58,52,51,0.7)',
	background: '#EEF0F8',
	primaryDark: '#1e1e2d',
	secondaryDark: '#1a1a27',
	neutralLight: '#a2a3b7',
	white: '#ffffff',
	boxShadow: '0px 1px 9px -3px rgba(0, 0, 0, 0.25)',
};

export type Theme = typeof lightTheme;

export const themes = {
	light: lightTheme,
};
