import styled from 'styled-components';

export const ErrorContainer = styled.div`
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: ${p => p.theme.white};
	display: flex;
	flex: 1 1;
	height: 100vh;
	text-align: center;
`;

export const ImageContainer = styled.div`
	margin: auto;
`;

export const ImageError = styled.img`
	width: 500px;
`;

export const ErrorTitle = styled.h1`
	font-size: 2rem;
	font-weight: 400;
	padding-top: 1.5rem;
`;
