import React from 'react';
import NotFound from 'components/ErrorPages/NotFound';

const ServerErrorPage = React.lazy(
	() => import('components/ErrorPages/ServerError')
);
const ForbiddenPage = React.lazy(
	() => import('components/ErrorPages/Unauthorized')
);
const SubscriptionsPage = React.lazy(() => import('containers/Subscriptions'));

const ExperimentsPage = React.lazy(() => import('containers/Experiments'));
const ExperimentOldPage = React.lazy(() => import('containers/Experiment-old'));
const ExperimentPage = React.lazy(() => import('containers/Experiment-new'));

const AppsPage = React.lazy(() => import('containers/Apps'));
const AppDetailsPage = React.lazy(() => import('containers/App'));

const FoldingSearchPage = React.lazy(() => import('containers/FoldingSearch'));
const FoldingPage = React.lazy(() => import('containers/Folding'));

const PatentSearchesPage = React.lazy(
	() => import('containers/PatentSearches')
);
const PatentSearchDetailsPage = React.lazy(
	() => import('containers/PatentSearch')
);

const SimulationsPage = React.lazy(() => import('containers/Simulations'));
const SimulationsDetailsPage = React.lazy(
	() => import('containers/Simulation')
);

export const HOME = '/';

export const INTERNAL_ERROR = '/500';
export const FORBIDDEN = '/403';

export const EXPERIMENTS = '/experiments';
export const EXPERIMENT_OLD = '/experiments-old/:experimentId';
export const EXPERIMENT = '/experiments/:experimentId';

export const SUBSCRIPTIONS = '/subscriptions';
export const USER_DETAILS = '/users/:userId';

export const APPS = '/apps';
export const APP_DETAILS = '/apps/:appId';

export const FOLDING = '/folding';
export const FOLDING_DETAILS = '/folding/:appId';

export const PATENTSEARCH = '/patent-search';
export const PATENTSEARCH_DETAILS = '/patent-search/:searchId';

export const SIMULATIONS = '/simulations';
export const SIMULATIONS_DETAILS = '/simulation/:simulationid';
export interface RouteConfig {
	offLayout?: boolean;
	exact?: boolean;
	path: string;
	component: any;
	checkAccess?: Function;
	publicPath?: boolean;
}

export const routesConfig: RouteConfig[] = [
	{
		exact: true,
		path: EXPERIMENTS,
		component: ExperimentsPage,
	},
	{
		exact: true,
		path: EXPERIMENT,
		component: ExperimentPage,
	},
	{
		exact: true,
		path: EXPERIMENT_OLD,
		component: ExperimentOldPage,
	},
	{
		exact: true,
		path: SUBSCRIPTIONS,
		component: SubscriptionsPage,
	},
	{
		exact: true,
		path: APPS,
		component: AppsPage,
	},
	{
		exact: true,
		path: APP_DETAILS,
		component: AppDetailsPage,
	},
	{
		exact: true,
		path: FOLDING,
		component: FoldingSearchPage,
	},
	{
		exact: true,
		path: FOLDING_DETAILS,
		component: FoldingPage,
	},
	{
		exact: true,
		path: PATENTSEARCH,
		component: PatentSearchesPage,
	},
	{
		exact: true,
		path: PATENTSEARCH_DETAILS,
		component: PatentSearchDetailsPage,
	},
	{
		exact: true,
		path: SIMULATIONS,
		component: SimulationsPage,
	},
	{
		exact: true,
		path: SIMULATIONS_DETAILS,
		component: SimulationsDetailsPage,
	},

	{
		exact: true,
		path: INTERNAL_ERROR,
		component: ServerErrorPage,
		offLayout: true,
	},
	{
		exact: true,
		path: FORBIDDEN,
		component: ForbiddenPage,
		offLayout: true,
	},

	{
		path: '*',
		component: NotFound,
		offLayout: true,
		publicPath: true,
	},
];
