import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { clearErrorMessage } from 'core/store/errors';
import * as ROUTES from 'core/routes';
import AuthorizationController from 'core/auth/AuthorizationController';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { authInterceptor, post } from './core/api';
import { Alert, Spin } from 'antd';

const Flex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

function useAuthenticatedEndpoint() {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		post('/users/authenticate', null)
			.then(() => setLoading(false))
			.catch(() => setError(false));
	}, []);

	return { loading, error };
}

function ChildrenAfterCall({ children }) {
	// TODO add error handling
	const { error, loading } = useAuthenticatedEndpoint();

	if (loading)
		return (
			<Flex>
				<Spin size='large' />
			</Flex>
		);
	if (error)
		return (
			<Alert type='error'>
				Fatal Error ! There are some errors that prevented us of
				proceeding with your auth flow
			</Alert>
		);
	return children;
}

function Guard({ children }) {
	const { user, isAuthenticated } = useAuth0();

	if (isAuthenticated && !user?.app_metadata?.activated) {
		return <ChildrenAfterCall>{children}</ChildrenAfterCall>;
	}

	return children;
}

const DesktopRoutes = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearErrorMessage());
	}, [location, dispatch]);

	return (
		<Guard>
			<Switch>
				<Redirect exact from={ROUTES.HOME} to={ROUTES.EXPERIMENTS} />
				{ROUTES.routesConfig.map(route => (
					<Route
						exact={!!route.exact}
						key={route.path}
						path={route.path}
						render={props => (
							<AuthorizationController route={route} {...props}>
								<route.component />
							</AuthorizationController>
						)}
					/>
				))}
			</Switch>
		</Guard>
	);
};

function RouterBase() {
	const { getAccessTokenSilently } = useAuth0();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const addToken = async () => {
			const token = await getAccessTokenSilently();
			authInterceptor.setAuthGetter(token);
			setLoading(false);
		};
		addToken();
	}, [getAccessTokenSilently]);

	if (loading) {
		return (
			<Flex>
				<Spin size='large' />
			</Flex>
		);
	}

	return <DesktopRoutes />;
}
const Router = withAuthenticationRequired(RouterBase);
export default Router;
