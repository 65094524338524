import 'antd/dist/antd.css';
import Router from './Router';
import { Overrides } from './core/styles/overrides';
import { Auth0ProviderWithHistory } from './auth0-provider-with-history';

function App() {
	return (
		<>
			<Overrides />
			<Auth0ProviderWithHistory>
				<Router />
			</Auth0ProviderWithHistory>
		</>
	);
}

export default App;
