import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router-dom';
import * as ROUTES from 'core/routes';
import Layout from 'core/layout';
import { RouteConfig } from '../routes';
import { useAuth0 } from '@auth0/auth0-react';

interface Props extends RouteProps {
	route: RouteConfig;
	children: ReactElement;
}

export default function AuthorizationController({
	route,
	children,
	...props
}: Props) {
	const { isAuthenticated } = useAuth0();
	const canAccess = useSelector(state => {
		if (route.checkAccess) return route.checkAccess(state);
		return true;
	});

	const clonedChildren = React.cloneElement(children, props);
	if (route.publicPath) return clonedChildren;
	if (!isAuthenticated) {
		return (
			<Redirect
				to={{
					pathname: '/',
					state: { from: props.location },
				}}
			/>
		);
	}

	if (!canAccess) {
		return (
			<Redirect
				to={{
					pathname: ROUTES.HOME,
					state: { from: props.location },
				}}
			/>
		);
	}

	if (route.offLayout) {
		return clonedChildren;
	}
	return <Layout>{clonedChildren}</Layout>;
}
