import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { repoReducer } from '@instadeep/redux-as-repo';
import storage from 'redux-persist/lib/storage';
import rootSaga from './rootSaga';
import reducerRegistry from './reducerRegistry';
import { RESET_STATE } from './globalTypes';
import { i18nInitialState } from './i18n';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
	}
}

const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: compose;

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
	key: 'dc_admin_dashboard',
	storage,
	whitelist: ['lang'],
};

const preloadedState = {
	lang: i18nInitialState,
};

export default function configureStore(initialState = preloadedState) {
	const combine = (reducers): Function => {
		const reducerNames = Object.keys(reducers);
		Object.keys(initialState).forEach(item => {
			if (reducerNames.indexOf(item) === -1) {
				reducers[item] = (state = null) => state;
			}
		});
		return combineReducers(reducers);
	};
	// register repository reducer (external)
	reducerRegistry.register('repository', repoReducer);
	const reducer = combine(reducerRegistry.getReducers());

	const rootReducer = (state, action) => {
		if (action.type === RESET_STATE) {
			return initialState;
		}
		return reducer(state, action);
	};
	const persistedReducer = persistReducer(persistConfig, rootReducer);

	const store = createStore(
		persistedReducer,
		initialState,
		composeEnhancers(applyMiddleware(sagaMiddleware))
	);
	const persistor = persistStore(store as any);
	sagaMiddleware.run(rootSaga);
	reducerRegistry.setChangeListener(reducers => {
		store.replaceReducer(combine(reducers) as any);
	});
	return { store, persistor };
}
